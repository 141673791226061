<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆自编号" prop="vehicleId" v-if="form.vehicleId">
        <a-input disabled v-model="form.vehicleId" placeholder="请输入车辆自编号" />
      </a-form-model-item>
      <a-form-model-item label="车辆名称" prop="name">
        <a-input disabled v-model="form.name" placeholder="车辆名称" />
      </a-form-model-item>
      <a-form-model-item label="车辆牌号" prop="licensePlateNum">
        <a-input disabled v-model="form.licensePlateNum" placeholder="车辆牌号" />
      </a-form-model-item>
      <a-form-model-item label="汇总日期" prop="summaryDate" v-if="readType !== 'summary'">
        <a-input disabled v-model="form.summaryDate" placeholder="汇总日期" />
      </a-form-model-item>
      <a-form-model-item label="智驾里程(km)" prop="autoCtlOdom">
        <a-input disabled v-model="form.autoCtlOdom" placeholder="请输入智驾里程" />
      </a-form-model-item>
      <a-form-model-item label="遥控里程(km)" prop="remoteCtlOdom">
        <a-input disabled v-model="form.remoteCtlOdom" placeholder="请输入遥控里程" />
      </a-form-model-item>
      <a-form-model-item label="智驾时长" prop="autoCtlTime">
        <a-input disabled v-model="form.autoCtlTime" placeholder="请输入智驾时长" />
      </a-form-model-item>
      <a-form-model-item label="遥控时长" prop="remoteCtlTime">
        <a-input disabled v-model="form.remoteCtlTime" placeholder="请输入遥控时长" />
      </a-form-model-item>
      <a-form-model-item label="总里程(km)" prop="odo">
        <a-input disabled v-model="form.odo" placeholder="请输入总里程" />
      </a-form-model-item>
      <a-form-model-item label="总时长" prop="ctlTime">
        <a-input disabled v-model="form.ctlTime" placeholder="请输入总时长" />
      </a-form-model-item>
      <!-- <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div> -->
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleDailyData, addVehicleDailyData, updateVehicleDailyData } from '@/api/iot/vehicleDailyData'
import { huorsFormat } from '@/utils/util'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        summaryDate: null,
        autoCtlOdom: null,
        remoteCtlOdom: null,
        autoCtlTime: null,
        remoteCtlTime: null,
        odo: null,
        ctlTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      readType: ''
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        summaryDate: null,
        autoCtlOdom: null,
        remoteCtlOdom: null,
        autoCtlTime: null,
        remoteCtlTime: null,
        odo: null,
        ctlTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.readType = ids
      if (ids === 'summary') {
        this.form = JSON.parse(JSON.stringify(row))
        this.open = true
        this.formTitle = '查看'
        this.formatFormData()
      } else {
        getVehicleDailyData(id).then(response => {
          this.form = response.data
          if (!this.form.name) {
            this.form.name = row.name
          }
          if (!this.form.licensePlateNum) {
            this.form.licensePlateNum = row.licensePlateNum
          }
          this.open = true
          this.formTitle = '查看'
          this.formatFormData()
        })
      }
    },
    formatFormData() {
      this.form.ctlTime = huorsFormat(this.form.ctlTime)
      this.form.remoteCtlTime = huorsFormat(this.form.remoteCtlTime)
      this.form.autoCtlTime = huorsFormat(this.form.autoCtlTime)
      this.form.autoCtlOdom = this.toFixedOdom(this.form.autoCtlOdom)
      this.form.remoteCtlOdom = this.toFixedOdom(this.form.remoteCtlOdom)
      this.form.odo = this.toFixedOdom(this.form.odo)
    },
    toFixedOdom(s) {
      return (s / 1000).toFixed(3)
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleDailyData(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleDailyData(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
